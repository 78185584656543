import React from 'react';
import './index.css'
import logo from './avi_logo.png'

// Create a simple Header component
const Header = () => {
  return (
  <><div className="header"><img src={logo} alt="Logo" /></div>
  <p className="header">Päästäksesi koulutusmateriaaleihin sinulla tulee olla CLANED tunnus ja rekisteröitynyt AVI:n koulutuksiin.<br /> Jos sinulla on jo tunnukset, valitse alta "Kirjaudu". Tunnukset voit luoda "Rekisteröidy" kautta.</p></>
  )
}

// Create a Card component with two buttons
const Card = ({ url1, url2 }) => {
  return (
    <div className="card">
      <button onClick={() => window.location.href = url1} className='button-59'>Kirjaudu</button>
      <button onClick={() => window.location.href = url2} className='button-59'>Rekisteröidy</button>
    </div>
  );
}

// Create the main App component
const App = () => {
  // Define the URLs for the buttons here
  const url1 = "https://avi.claned.com/";
  const url2 = "https://avi.claned.com/#/register?token=3c8ebb9a-a4f1-4d81-b087-b6c600947fd4&lang=fi";

  return (
    <div>
      <Header />
      <Card url1={url1} url2={url2} />
    </div>
  );
}

export default App;
